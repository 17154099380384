<template>
  <div class="wrapper" v-if="isShow">
    <div class="empty"></div>
    <van-skeleton title :row="3" />
  </div>
</template>

<script>
import { Skeleton } from 'vant'
import Vue from 'vue'

Vue.use(Skeleton)

export default {
  name: 'MyLoading',
  components: {
    Skeleton,
  },
  data() {
    return {
      isShow: true,
    }
  },
  watch: {
    isShow(value) {
      !value && this.handleColse()
    },
  },
}
</script>
<style scoped>
.wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  .empty {
    height: 40px;
  }
}
</style>

import Vue from 'vue'
import Instance from './MyLoadingInstance.vue'
import { onMessage } from '@/store/channel'

const Constructor = Vue.extend(Instance)
const id = 'mini-dialog-loading'

const createDiv = () => {
  const div = document.createElement('div')
  div.setAttribute('id', id)
  document.body.appendChild(div)
  return div
}

const removeDiv = () => {
  const div = document.getElementById(id)
  div && div.remove()
}

export default function () {
  return new Promise((resolve) => {
    let timer 
    const instance = new Constructor()
    instance.$mount()
    const div = createDiv()
    div.appendChild(instance.$el)
    instance.handleColse = function () {
      instance.isShow = false
      if (timer) {
        clearTimeout(timer)
      }
      resolve()
      removeDiv()
    }
    timer = setTimeout(() => instance.handleColse(), 3000)
    onMessage((data) => {
      if (data.action == 'http-end') {
        instance.handleColse()
      }
    })
    resolve(instance)
  })
}


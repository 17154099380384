import dayjs from 'dayjs'
import { EASYSHOP_IMAGES_HOST, EASYSHOP_IMAGES_STYLES } from './global'

export function stamp2date (stamp) {
  if (!stamp) {
    return ''
  }
  let _stamp = stamp.toString().length == 10 ? stamp * 1000 : stamp
  return dayjs(_stamp).format('YYYY-MM-DD HH:mm:ss')
}

export function scoreDate () {
  return dayjs().add(32, 'hour').format('YYYY-MM-DD HH:mm')
}

export function clone (data) {
  return data ? JSON.parse(JSON.stringify(data)) : null
}

export function randStr () {
  return (
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substring(2, 10)
  )
}

export function string2json (string) {
  try {
    return JSON.parse(string)
  } catch (error) {
    return null
  }
}

export function json2string (json) {
  return JSON.stringify(json)
}

export function getOssUrl (string, style) {
  const suffix = EASYSHOP_IMAGES_STYLES.includes(style)
    ? `?x-oss-process=style/w${style}`
    : ''
  return EASYSHOP_IMAGES_HOST + encodeURIComponent(string) + suffix
}

export function shuffle (arr) {
  const result = []
  let random
  while (arr.length > 0) {
    random = Math.floor(Math.random() * arr.length)
    result.push(arr[random])
    arr.splice(random, 1)
  }
  return result
}

// user token
export function setToken (string) {
  localStorage.setItem('user_token', string)
}

export function removeToken () {
  localStorage.removeItem('user_token')
}

export function getToken () {
  return localStorage.getItem('user_token')
}

// pay status 支付状态
export function setPaid () {
  localStorage.setItem('paid_h5', '1')
}

export function getPaid () {
  const key = 'paid_h5'
  const paid = localStorage.getItem(key)
  if (paid) {
    localStorage.removeItem(key)
    return true
  }
  return false
}

// activity_rule
export function setRule (string) {
  localStorage.setItem('activity_rule', string)
}

export function getRule () {
  return localStorage.getItem('activity_rule')
}
const channel = new MessageChannel()

export function onMessage (callback) {
  channel.port1.onmessage = (event) => {
    callback(event.data)
  }
}

export function postMessage (data) {
  channel.port2.postMessage(data)
}
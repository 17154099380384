import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import '@/utils/vant.js'
import 'amfe-flexible'
import Vant from 'vant'
import 'vant/lib/index.css'
// pinia
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.use(Vant);
Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
// VConsole
// import VConsole from 'vconsole'
// new VConsole()

new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')

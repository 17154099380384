import Vue from 'vue'
import VueRouter from 'vue-router'
import MyLoading from '@/components/dialog/MyLoading'
import { getToken } from '@/utils/tools'

Vue.use(VueRouter)

const routes = [
  {
    // 空白页
    path: '/',
    name: 'entry',
    meta: { requireAuth: true },
    component: () => import('@/views/EntryView.vue')
  },
  {
    // 空白页
    path: '/c/:code',
    name: 'channel',
    meta: { requireAuth: true },
    component: () => import('@/views/EntryView.vue')
  },
  {
    // 首页
    path: '/home',
    name: 'home',
    meta: { requireAuth: true },
    component: () => import('@/views/HomeView.vue')
  },
  {
    // 特卖
    path: '/sold',
    name: 'sold',
    // meta: { requireAuth: false },
    component: () => import('@/views/SoldView.vue')
  },
  {
    // 订单
    path: '/order',
    name: 'order',
    meta: { requireAuth: false },
    component: () => import('@/views/OrderView.vue')
  },
  {
    // 我的
    path: '/mine',
    name: 'mine',
    meta: { requireAuth: false },
    component: () => import('@/views/MineView.vue')
  },
  {
    // 详情
    path: '/product',
    name: 'product',
    meta: { requireAuth: false },
    component: () => import('@/views/ProductView.vue')
  },
  {
    // 搜索
    path: '/search',
    name: 'search',
    meta: { requireAuth: false },
    component: () => import('@/views/SearchView.vue')
  },
  {
    // 待付款
    path: '/pay',
    name: 'pay',
    meta: { requireAuth: false },
    component: () => import('@/views/OrderPayView.vue')
  },
  {
    // 已付款
    path: '/paid',
    name: 'paid',
    meta: { requireAuth: false },
    component: () => import('@/views/OrderPaidView.vue')
  },
  // LogisticDetailView
  {
    // 物流详情
    path: '/logistics',
    name: 'logistics',
    meta: { requireAuth: false },
    component: () => import('@/views/OrderLogistics.vue')
  },
  {
    // 常见问题
    path: '/issue',
    name: 'issue',
    meta: { requireAuth: false },
    component: () => import('@/views/IssueView.vue')
  },
  {
    // 客服咨询
    path: '/service',
    name: 'service',
    meta: { requireAuth: false },
    component: () => import('@/views/ServiceView.vue')
  },
  {
    // 投诉建议
    path: '/suggestion',
    name: 'suggestion',
    meta: { requireAuth: false },
    component: () => import('@/views/SuggestionView.vue')
  },
  {
    // 隐私保护
    path: '/private',
    name: 'private',
    meta: { requireAuth: false },
    component: () => import('@/views/PrivateView.vue')
  },
  {
    path: '*',
    name: '404',
    meta: { requireAuth: true },
    component: () => import('@/views/NorouterView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name == 'entry') {
    return next()
  }
  const token = getToken()
  if (!token) {
    router.push({ name: 'entry' }, () => { })
  }
  if (['home', 'sold', 'order', 'mine', 'entry', null].includes(from.name) && ['home', 'sold', 'order', 'mine'].includes(to.name)) {
    if (!to.meta.isCache) {
      to.meta.isCache = true
      await MyLoading()
    }
  }
  if (['product', 'paid'].includes(to.name)) {
      await MyLoading()
  }
  next()
})

export default router
